/**
 * 路由模式
 * A. map 路由映射模式
 * B. 项目组件形式
 */

import Home from '@/pages/Home';
import Leaf from '@/pages/Leaf';
import Tree from '@/pages/Tree';
import BaseLayout from '@/components/Layouts/BaseLayout';
import { RouteObject, useRouteError } from 'react-router-dom';

export type Route = {
  enable?: boolean;
  icon?: JSX.Element;
  name?: string;
  children?: Route[];
  auth?: boolean;
  guest?: boolean;
} & RouteObject;

// 自动引入protected目录里的子路由
const files = require.context('./protected', true, /\.tsx$/);
let childrenRoutes: Route[] = [];
files.keys().forEach((file: string) => {
  childrenRoutes.push(...files(file).default);
});

// 自动引入public目录里的子路由
const publicRoutesfiles = require.context('./public', true, /\.tsx$/);
let publicRoutes: Route[] = [];
publicRoutesfiles.keys().forEach((file: string) => {
  publicRoutes.push(...publicRoutesfiles(file).default);
});

console.log('publicRoutes', publicRoutes);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Dang!</div>;
}

const RouterMap: Route[] = [
  {
    path: '/app',
    element: <BaseLayout />,
    // loader: rootLoader,
    // action: () => {},
    errorElement: <ErrorBoundary />,
    children: childrenRoutes,
  },
  ...publicRoutes,
];

export default RouterMap;
