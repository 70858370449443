import Home from '@/pages/Home';
import Leaf from '@/pages/Leaf';
import Tree from '@/pages/Tree';
import { type Route } from '@/routes';
const Routes: Route[] = [
  {
    path: 'leaf',
    element: <Leaf />,
    loader: () => {
      const pageConfig = {
        displayLayout: true,
      };
      console.log('first');
      return pageConfig;
    },
  },
];

export default Routes;
