import Login from '@/pages/Login';
import { type Route } from '@/routes';
const Routes: Route[] = [
  {
    path: 'login',
    element: <Login />,
  },
];

export default Routes;
