import { Route, Routes, Link, Outlet, useLoaderData } from 'react-router-dom';

export default function About(props: any) {
  const albums = useLoaderData();
  console.log('BaseLayoutprops', albums);
  return (
    <>
      <main>
        <h2>Welcome to the about!</h2>
        <p>You can do this, I believe in you.</p>
      </main>
      <nav>
        <Link to="/about">About</Link>
      </nav>
    </>
  );
}
